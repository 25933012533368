import React from "react";
import { Button, Container, Row, Col, Card, Image } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { CiVideoOn } from "react-icons/ci";
import { RiArrowRightLine } from "react-icons/ri";
import { BiCategory } from "react-icons/bi";
import { useCreateEventMutation } from "../../../../../services/event-service";
import {
  CalenderIcon,
  Clock,
  GameConsole,
  LocationIcon,
} from "../../../../assets";

const CreateEventMessage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const event = location.state?.eventData;
  const [createEvent] = useCreateEventMutation();
  console.log("event", event);
const handleFormSubmit = async (e) => {
  try {
    const response = await createEvent(event);
    console.log("response create events", response);
    const { code, message } = response["data"];
    if (code === "00") {
      toast.success("Event created successfully");
      navigate("/events");
    } else {
      toast.error(message || "Something went wrong");
    }
  } catch (error) {
    // Error handling with specific message
    const errorMessage = error?.data?.message || "Something went wrong";
    toast.error(errorMessage);
    console.error("Error:", error);
  }
};

  return (
    <div>
      <div className="bg-enquiries">
        <Container className="vh-100 d-flex align-items-center justify-content-center">
          <Row className="justify-content-center">
            <Col md={12} xs={12}>
              <div className="py-13 pt-lg-20 pb-lg-20">
                <h1 className="display-3 fw-medium text-white mb-5">
                  One last check...
                </h1>
                <div
                  className="rounded-1"
                  style={{ display: "block", position: "initial" }}
                >
                  <Col className="mx-auto">
                    <Card
                      className="bg-white rounded-4 object-fit-cover d-flex flex-column"
                      key={event.id}
                      style={{ height: "250px", maxWidth: "468px" }}
                    >
                      <Row className="g-0 h-100">
                        {event.image && (
                          <Col className="rounded-start-4">
                            <Image
                              alt="Event Image"
                              src={event.image}
                              className="img-fluid w-100 h-100 object-fit-cover rounded-start-4"
                            />
                          </Col>
                        )}
                        <Col className="d-flex flex-column justify-content-between">
                          <Card.Body
                            className="d-flex flex-column justify-content-between"
                            style={{ maxWidth: "260px" }}
                          >
                            <div>
                              <div className="d-flex justify-content-between">
                                <Link
                                  className="text-decoration-none text-black"
                                  to={`/events/event-details/${event.id}`}
                                >
                                  <Card.Title className="event-card-title">
                                    {event.title?.length > 22
                                      ? `${event.title.substring(0, 22)}...`
                                      : event.title}
                                  </Card.Title>
                                </Link>
                              </div>

                              <ul className="list-unstyled d-flex flex-column event-card-text my-1">
                                {event.category === "VIRTUAL_SESSION" ? (
                                  <li className="icon-link pb-1 text-decoration-underline">
                                    <a
                                      href={event.link}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <CiVideoOn className="fs-6 me-2 " />
                                      {event.link?.length > 22
                                        ? `${event.link.substring(0, 22)}...`
                                        : event.link}
                                    </a>
                                  </li>
                                ) : (
                                  <>
                                    {event.category === "MEDIA_ENGAGEMENT" && (
                                      <>
                                        <li className="icon-link pb-1">
                                          <Image
                                            src={LocationIcon}
                                            alt=""
                                            width={20}
                                          />
                                          {event.location?.nameOfVenue}-
                                          {event.location?.city}
                                        </li>
                                      </>
                                    )}
                                    {(event.category ===
                                      "IN_PERSON_SESSION_MENTORSHIP" ||
                                      event.category ===
                                        "IN_PERSON_SESSION_COUNSELLING" ||
                                      event.category ===
                                        "IN_PERSON_SESSION") && (
                                      <li className="icon-link pb-1">
                                        <Image
                                          src={LocationIcon}
                                          alt=""
                                          width={20}
                                        />
                                        {event.location?.nameOfVenue}-
                                        {event.location?.city}
                                      </li>
                                    )}
                                  </>
                                )}

                                <li className="icon-link pb-1 ">
                                  <Image src={Clock} alt="" width={20} />
                                  {event.stime} - {event.etime}
                                </li>

                                <li className="icon-link  pb-1 text-nowrap">
                                  <Image src={CalenderIcon} alt="" width={20} />
                                  {event.startDay} - {event.endDay}
                                </li>

                                <li className="icon-link pb-1 text-nowrap">
                                  <BiCategory className="fs-5" />
                                  {event.category}
                                </li>
                              </ul>
                            </div>
                            <div>
                              <div className="d-flex align-items-center">
                                <Image
                                  alt=""
                                  className="rounded-circle p-1 bg-light"
                                  src={GameConsole}
                                  width={24}
                                />
                                <hr className="hr w-100"></hr>
                              </div>

                              <Card.Text className="event-card-text text-small text-muted">
                                {event.description
                                  ? event.description.length > 50
                                    ? `${event.description.substring(0, 50)}...`
                                    : event.description
                                  : "No description"}
                              </Card.Text>
                            </div>
                          </Card.Body>
                        </Col>
                      </Row>
                    </Card>
                    <Row>
                      <Col className="text-center mt-5 mb-3">
                        <Button
                          variant="light"
                          className="rounded-5 me-2"
                          onClick={() => navigate(-1)}
                        >
                          Go back
                        </Button>
                        <Button
                          variant="dark"
                          type="submit"
                          onClick={handleFormSubmit}
                          className="rounded-5"
                        >
                          Publish <RiArrowRightLine />
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default CreateEventMessage;
