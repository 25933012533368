import React, { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import Avatar from "react-avatar";
import { Link } from "react-router-dom";
import EnableGroup from "./EnableGroup";
import { useDisabledMentorshipGroupsQuery } from "../../../../../../services/mentoring-service";
import SharedPagination from "../../../../../components/Pagination";
import PageLoader from "../../../../../components/PageLoader";

const DisabledMentorshipGroups = () => {
  const avatars = ["Jane", "Doe", "Alice", "Bob"];

  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [pageSize] = useState(6);
  const { data: response, isLoading } = useDisabledMentorshipGroupsQuery({
    page_index: currentPageIndex,
    page_size: pageSize,
    isEnabled: false,
  });

  const mentorGroup = response?.data;
  const totalPages = response?.totalPages;
  const totalElements = response?.totalElements;

  if (isLoading) return <PageLoader />;
  return (
    <Row className="my-4">
      <>
        {mentorGroup?.map((group) => (
          <Col key={group.id} className="p-3 m-0" md={4}>
            <Card
              className="border-0 p-2 rounded-3"
              style={{ backgroundColor: "#FAFAFA" }}
            >
              <Card.Body>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {avatars.map((name, index) => (
                    <div
                      key={index}
                      style={{
                        position: "relative",
                        zIndex: avatars.length - index,
                        marginLeft: index !== 0 ? "-15px" : 0,
                      }}
                    >
                      <Avatar name={name} size="40" round />
                    </div>
                  ))}
                  <div style={{ marginLeft: "-15px" }}>
                    <div
                      className="rounded-circle bg-white d-flex align-items-center justify-content-center"
                      style={{
                        width: "40px",
                        height: "40px",
                        fontSize: "14px",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                    >
                      +{avatars.length - 5}{" "}
                      {/* Adjust the number accordingly */}
                    </div>
                  </div>
                </div>
                <Row className="mt-5">
                  <Col>
                    <Card.Text
                      className="fw-bold mb-0"
                      style={{ fontSize: "16px" }}
                    >
                      {group.name}
                    </Card.Text>
                    <Card.Text
                      className="text-muted mb-0"
                      style={{ fontSize: "14px" }}
                    >
                      x members
                    </Card.Text>
                    <Link
                      className="text-decoration-none py-1"
                      to={`/e-mentoring/group-details/${group.id}`}
                    >
                      View More
                    </Link>
                  </Col>
                  <Col>
                    <EnableGroup
                      groupId={group.id}
                      groupEnabled={group.enabled}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </>
      <SharedPagination
        currentPage={currentPageIndex + 1}
        onPageChange={(newPage) => setCurrentPageIndex(newPage - 1)}
        totalPages={totalPages}
        pageSize={pageSize}
        totalElements={totalElements}
      />
    </Row>
  );
};

export default DisabledMentorshipGroups;
