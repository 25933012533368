import React, { useState, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import Select from "react-select";
import { assignType } from "../../../../../../models/request/create-user.request";
import {
  useAssignRolesMutation,
  useUserRolesQuery,
} from "../../../../../../services/auth-service";
import { useGetUsersListFilterQuery } from "../../../../../../services/user-service";

const AssignRole = () => {
  const [show, setShow] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [assignData, setAssignData] = useState<assignType>({
    username: "",
    roleName: "",
  });
  const [pageIndex, setPageIndex] = useState(0);
  const [userOptions, setUserOptions] = useState([]);

  const handleClose = () => {
    setShow(false);
    // Reset fields when the modal is closed
    setSelectedUser(null);
    setAssignData({ username: "", roleName: "" });
    setSearchTerm(""); // Reset search term
    setUserOptions([]); // Clear user options
  };
  const handleShow = () => setShow(true);

  const [assignRoleMutation] = useAssignRolesMutation();

  const { data: userResponse, isLoading } = useGetUsersListFilterQuery({
    page_index: pageIndex,
    size: 50,
    ...(searchTerm && { search: searchTerm }),
  });

  useEffect(() => {
    if (userResponse?.data) {
      const newOptions = userResponse.data.map((user) => ({
        value: user.phone,
        label: `${user.firstName} ${user.lastName}`,
      }));
      setUserOptions((prevOptions) => {
        // Avoid duplicate users when loading more pages
        if (pageIndex === 0) return newOptions;
        return [...prevOptions, ...newOptions];
      });
    }
  }, [userResponse, pageIndex]);

  const handleUserSelectChange = (selectedOption) => {
    setSelectedUser(selectedOption);
    setAssignData((prevEventData) => ({
      ...prevEventData,
      username: selectedOption.value,
    }));
  };

  const loadMoreUsers = () => {
    if (!isLoading) {
      setPageIndex((prevPageIndex) => prevPageIndex + 1);
    }
  };

  const handleInputChange = (inputValue) => {
    setSearchTerm(inputValue);
    setPageIndex(0); // Reset page index for new search
  };

  const handleFormSelectChanged: React.ChangeEventHandler<HTMLSelectElement> = (
    e
  ) => {
    const { name, value } = e.target;
    setAssignData((prevEventData) => ({
      ...prevEventData,
      [name]: value,
    }));
  };

  const { data: regionResponse } = useUserRolesQuery();

  const roles = regionResponse?.data;

  const handleCreateRole = async (e) => {
    e.preventDefault();
    try {
      const response = await assignRoleMutation(assignData);
      const { code, message } = response["data"];
      if (code === 200) {
        toast.success(message);
        handleClose();
      } else {
        toast.error(message || "Something went wrong");
      }
      handleClose();
    } catch (error) {
      toast.error(error.message);
      console.error("Error creating region:", error);
    }
  };

  return (
    <>
      <Button
        className="py-2 mx-1 rounded-5 "
        variant="success"
        style={{ fontSize: "13px" }}
        onClick={handleShow}
      >
        Assign Role +
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="fs-6">Assign role</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label style={{ fontSize: "14px" }}>User</Form.Label>
              <Select
                className="p-2"
                options={userOptions}
                onChange={handleUserSelectChange}
                value={selectedUser}
                isLoading={isLoading}
                isDisabled={isLoading}
                onMenuScrollToBottom={loadMoreUsers}
                onInputChange={handleInputChange} // Update search term
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label style={{ fontSize: "14px" }}>Role</Form.Label>
              <Form.Select
                className="p-2"
                aria-label="Default select example"
                name="roleName"
                onChange={handleFormSelectChanged}
              >
                <option className="text-muted" value="" disabled>
                  Select role
                </option>
                {roles
                  ?.filter((role) => role.name !== "default-roles-austria_test")
                  .map((role) => (
                    <option key={role.name} value={role.name}>
                      {role.name}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="rounded-5"
            variant="secondary"
            onClick={handleClose}
            style={{ fontSize: "14px" }}
          >
            Close
          </Button>
          <Button
            style={{ fontSize: "14px" }}
            className="rounded-5"
            variant="primary"
            onClick={handleCreateRole}
          >
            Assign Role
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AssignRole;
