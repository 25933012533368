import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AppConstants } from "../core/mocks/app-constants";
import { BaseResponse } from "../models/response/base-response";
import {
  approveRequestType,
  assignMentorGroupType,
  createAnnouncementType,
  createChannelType,
  CreateGroupEvent,
  createGroupType,
  createTopicType,
  editGroupType,
  mentoringParams,
  registerGroupType,
  removeMemberType,
} from "../models/request/mentoring-request";
import queryString from "query-string";

export const mentoringService = createApi({
  reducerPath: "mentoringService",
  baseQuery: fetchBaseQuery({
    baseUrl: `${AppConstants.ecounsellingBaseUrl}`,
  }),
  tagTypes: ["mentoring"],
  endpoints: (build) => ({
    allMentorshipGroups: build.query<BaseResponse<any>, mentoringParams>({
      query: (params) => ({
        url: `/mentorship/groups?${queryString.stringify(params)}`,
        method: "GET",
      }),
      providesTags: ["mentoring"],
    }),
    registerGroupMentorship: build.mutation<
      BaseResponse<any>,
      registerGroupType
    >({
      query: (body: registerGroupType) => ({
        url: "/mentorship/groups/register",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["mentoring"],
    }),
    createGroup: build.mutation<BaseResponse<any>, createGroupType>({
      query: (body: createGroupType) => ({
        url: "/mentorship/groups",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["mentoring"],
    }),
    editGroup: build.mutation<BaseResponse<any>, editGroupType>({
      query: (body: editGroupType) => ({
        url: "/mentorship/groups",
        method: "PATCH",
        body: body,
      }),
      invalidatesTags: ["mentoring"],
    }),
    disabledMentorshipGroups: build.query<BaseResponse<any>, mentoringParams>({
      query: (params) => ({
        url: `/mentorship/groups?${queryString.stringify(params)}`,
        method: "GET",
      }),
      providesTags: ["mentoring"],
    }),
    unapproveRequestList: build.query<BaseResponse<any>, mentoringParams>({
      query: (params) => ({
        url: `/mentorship/groups/requests?${queryString.stringify(params)}`,
        method: "GET",
      }),
      providesTags: ["mentoring"],
    }),
    approvedRequestList: build.query<BaseResponse<any>, mentoringParams>({
      query: (params) => ({
        url: `/mentorship/groups/requests?${queryString.stringify(params)}`,
        method: "GET",
      }),
      providesTags: ["mentoring"],
    }),
    getMentorList: build.query<BaseResponse<any>, mentoringParams>({
      query: (params) => ({
        url: `/counselling/counsellor?${queryString.stringify(params)}`,
        method: "GET",
      }),
      providesTags: ["mentoring"],
    }),
    approveRequest: build.mutation<BaseResponse<any>, approveRequestType>({
      query: (body: approveRequestType) => ({
        url: "/mentorship/groups/approve",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["mentoring"],
    }),
    removeMember: build.mutation<BaseResponse<any>, removeMemberType>({
      query: (body: removeMemberType) => ({
        url: "/mentorship/groups/remove-member",
        method: "PATCH",
        body: body,
      }),
      invalidatesTags: ["mentoring"],
    }),
    getMentorshipGroupByUserID: build.query<BaseResponse<any>, mentoringParams>(
      {
        query: (params) => ({
          url: `/mentorship/groups/user?${queryString.stringify(params)}`,
          method: "GET",
        }),
        providesTags: ["mentoring"],
      }
    ),
    assignMentorGroup: build.mutation<BaseResponse<any>, assignMentorGroupType>(
      {
        query: (body: assignMentorGroupType) => ({
          url: "/mentorship/groups/assign-mentors",
          method: "PATCH",
          body: body,
        }),
        invalidatesTags: ["mentoring"],
      }
    ),
    declineRequest: build.mutation<BaseResponse<any>, approveRequestType>({
      query: (body: approveRequestType) => ({
        url: "/mentorship/groups/decline",
        method: "PATCH",
        body: body,
      }),
      invalidatesTags: ["mentoring"],
    }),

    getGroupByID: build.query<BaseResponse<any>, number>({
      query: (id) => ({
        url: `/mentorship/groups/${id}`,
        method: "GET",
      }),
      providesTags: ["mentoring"],
    }),

    createTopicRequest: build.mutation<BaseResponse<any>, createTopicType>({
      query: (body: createTopicType) => ({
        url: "/mentorship-group-topic",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["mentoring"],
    }),

    createChannelRequest: build.mutation<BaseResponse<any>, createChannelType>({
      query: (body: createChannelType) => ({
        url: "/mentorship-group-links",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["mentoring"],
    }),
    createAnnouncementRequest: build.mutation<
      BaseResponse<any>,
      createAnnouncementType
    >({
      query: (body: createAnnouncementType) => ({
        url: "/mentorship-group-announcement",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["mentoring"],
    }),

    createGroupEvents: build.mutation<BaseResponse<any>, CreateGroupEvent>({
      query: (body: CreateGroupEvent) => ({
        url: "/mentorship-group-event",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["mentoring"],
    }),
    getGroupEvents: build.query<BaseResponse<any>, mentoringParams>({
      query: (params) => ({
        url: `/mentorship-group-event/list?${queryString.stringify(params)}`,
        method: "GET",
      }),
      providesTags: ["mentoring"],
    }),
  }),
});

export const {
  useAllMentorshipGroupsQuery,
  useRegisterGroupMentorshipMutation,
  useCreateGroupMutation,
  useEditGroupMutation,
  useDisabledMentorshipGroupsQuery,
  useUnapproveRequestListQuery,
  useApprovedRequestListQuery,
  useApproveRequestMutation,
  useRemoveMemberMutation,
  useGetMentorshipGroupByUserIDQuery,
  useAssignMentorGroupMutation,
  useDeclineRequestMutation,
  useGetGroupByIDQuery,
  useCreateTopicRequestMutation,
  useCreateChannelRequestMutation,
  useGetMentorListQuery,
  useCreateAnnouncementRequestMutation,
  useCreateGroupEventsMutation,
  useGetGroupEventsQuery,
} = mentoringService;
