import { GridColDef } from "@mui/x-data-grid";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { RiDeleteBin6Line } from "react-icons/ri";

const UsersRoleTableComponent = (handleDeleteRole) => {
  const UsersRoleTable: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      headerClassName: "header--bg",
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      headerClassName: "header--bg",
    },
    {
      field: "actions",
      headerName: "Actions",
      headerClassName: "header--bg",
      flex: 1,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        console.log("Row data:", params?.row.name); // Debugging line
        return (
          <div>
            <Link to={`/users/view-users-roles/${params?.row?.name}`}>
              <Button
                variant="light"
                style={{ fontSize: "12px" }}
                className="rounded-5"
              >
                View Users
              </Button>
            </Link>
            <Button
              variant="transparent"
              className="m-0 p-0 bg-transparent border-0"
              onClick={() =>
                handleDeleteRole(params?.row?.id, params?.row?.name)
              }
            >
              <RiDeleteBin6Line
                className="text-danger"
                style={{ fontSize: "20px" }}
              />
            </Button>
          </div>
        );
      },
    },
  ];

  return UsersRoleTable;
};

export default UsersRoleTableComponent;
