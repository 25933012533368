import React, { useState } from "react";
import { Col, Container, Form, Navbar, Row } from "react-bootstrap";
import { IoSearchOutline } from "react-icons/io5";
import { RxHamburgerMenu } from "react-icons/rx";
import UserRoleDataTable from "./components/UserRoleDataTable";
import ProtectedRoutes from "../../../auth/utils/ProtectedRoutes";
import CreateUserRole from "./components/CreateUserRole";
import AssignRole from "./components/AssignRole";

const UserRole = () => {
  const [searchQuery, setSearchQuery] = useState("");

  return (
    <Container fluid>
      <Navbar expand="lg" className="py-3 p-4 bg-transparent">
        <Row className="w-100 align-items-center">
          <Col xs="auto">
            <Navbar.Brand className="fs-4">User Roles</Navbar.Brand>
          </Col>
          <Col xs="auto" className="ms-auto position-relative">
            <span className="position-absolute ps-2 pt-2 py-auto">
              <IoSearchOutline />
            </span>
            <Form.Control
              type="search"
              className="py-2 border border-1 rounded-5"
              style={{ paddingLeft: "1.8rem" }}
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </Col>
          <Col xs="auto">
            <CreateUserRole />
          </Col>
          <Col xs="auto">
            <AssignRole />
          </Col>
        </Row>
      </Navbar>
      <Row>
        <Col>
          <UserRoleDataTable searchQuery={searchQuery} />
        </Col>
      </Row>
    </Container>
  );
};

export default ProtectedRoutes(UserRole, {
  allowedRoles: ["Super Admin", "Admin"],
});
