import Select, { MultiValue } from "react-select";
import { Badge, Button, Card, Col, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import { GrFormClose } from "react-icons/gr";
import { useGetUsersListFilterQuery } from "../../../../../../services/user-service";
import {
  useCreateBulkParticipantsMutation,
  useDeleteEventParticipantsMutation,
} from "../../../../../../services/event-service";

const AdminParticipantEvent = ({ EventId, eventparticipants }) => {
  const roles = sessionStorage.getItem("roles");
  const allowedRoles = ["Admin", "Super Admin", "Call Center Manager", "Usher"];
  const hasAllowedRole =
    roles && allowedRoles.some((role) => roles.includes(role));

  const [searchTerm, setSearchTerm] = useState("");
  const [pageIndex, setPageIndex] = useState(0);
  const [participantsOptions, setParticipantsOptions] = useState([]);
  const [selectedParticipants, setSelectedParticipants] = useState([]);

  const { data: users, isLoading } = useGetUsersListFilterQuery({
    page_index: pageIndex,
    size: 50,
    ...(searchTerm && { search: searchTerm }),
  });

  useEffect(() => {
    if (users?.data) {
      const newOptions = users.data.map((user) => ({
        value: user.id,
        label: `${user.firstName} ${user.lastName}`,
        phone: user.phone,
      }));
      setParticipantsOptions((prevOptions) => {
        // Reset options if on a new search
        return pageIndex === 0 ? newOptions : [...prevOptions, ...newOptions];
      });
    }
  }, [users, pageIndex]);

  const loadMoreUsers = () => {
    if (!isLoading) {
      setPageIndex((prevPageIndex) => prevPageIndex + 1);
    }
  };

  const handleInputChange = (inputValue) => {
    setSearchTerm(inputValue);
    setPageIndex(0); // Reset to fetch from the beginning on new search
  };

  const handleSelectChange = (newValue: MultiValue<any>) => {
    setSelectedParticipants([...newValue]);
  };
  const [createBulkParticipants] = useCreateBulkParticipantsMutation();
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      if (selectedParticipants.length === 0) {
        toast.error("Please select at least one participant.");
        return;
      }
      const participantsPayload = selectedParticipants.map((participant) => ({
        name: participant.label,
        userId: participant.value,
        phoneNumber: participant.phone,
      }));
      const response = await createBulkParticipants({
        eventId: EventId,
        participants: participantsPayload,
      });
      const { code, message } = response["data"];
      if (code === "00") {
        toast.success("Participants have been added to this event");
        setSelectedParticipants([]);
      } else {
        toast.error(message || "Something went wrong");
      }
    } catch (error) {
      toast.error(error.message);
      console.error("Error:", error);
    }
  };

  const [deleteEventParticipants] = useDeleteEventParticipantsMutation();

  const handleUnregister = async (userId) => {
    try {
      const response = await deleteEventParticipants({
        eventId: EventId,
        participantId: userId,
      });
      const { code, message } = response["data"];
      console.log("Response:", response);
      if (code === "00") {
        const removedParticipant = eventparticipants.find(
          (participant) => participant.userId === userId
        );
        toast.success(
          `${removedParticipant.name} has been removed from this event`
        );
      } else {
        toast.error(message || "Something went wrong");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(error.message);
    }
  };

  const [storedUserId] = useState(sessionStorage.getItem("userId"));
  const userId = parseInt(storedUserId);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      border: "none",
      borderRadius: "2rem",
    }),
  };
  return (
    <Row className="">
      <Card.Text className="full-details-event-description m-0">
        <div className="d-flex align-items-center">
          <h5 className="py-1 w-25 m-0 event-label">Participants</h5>
          <hr className="hr w-75" />
        </div>
        <Badge
          pill
          bg="light"
          style={{ fontSize: "13px" }}
          className="text-dark ms-auto fw-normal"
        >
          Total : {eventparticipants.length}
        </Badge>
        {hasAllowedRole && (
          <>
            {eventparticipants.length > 0
              ? eventparticipants.map((participant) => (
                  <Badge
                    pill
                    bg={userId === participant.userId ? "dark" : "light"}
                    text={userId === participant.userId ? "white" : "dark"}
                    className="me-1 p-2 fw-normal"
                    key={participant.userId}
                    style={{ fontSize: "12px" }}
                  >
                    {participant.name}

                    <GrFormClose
                      onClick={() => handleUnregister(participant.userId)}
                      className="ms-1 fs-6 text-dark"
                    />
                  </Badge>
                ))
              : null}
          </>
        )}
      </Card.Text>
      {hasAllowedRole && (
        <div>
          <Col className="mt-3">
            <Select
              className="basic-multi-select"
              classNamePrefix="select"
              options={participantsOptions}
              isDisabled={isLoading}
              placeholder="Add Participants to Events"
              isLoading={isLoading}
              styles={customStyles}
              isMulti
              value={selectedParticipants}
              onChange={handleSelectChange}
              onInputChange={handleInputChange} // Update search term
              onMenuScrollToBottom={loadMoreUsers} // Load more when scrolling
            />
          </Col>
          <Row className="mt-2">
            <Col className="d-grid">
              <Button
                onClick={handleFormSubmit}
                variant="dark"
                style={{ fontSize: "14px", borderRadius: "2rem" }}
              >
                Add +
              </Button>
            </Col>
          </Row>
        </div>
      )}
    </Row>
  );
};

export default AdminParticipantEvent;
