import React, { useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import Avatar from "react-avatar";
import AssignMentorToGroup from "./AssignMentorToGroup";
import { Link, useNavigate } from "react-router-dom";
import { useAllMentorshipGroupsQuery } from "../../../../../../services/mentoring-service";
import { registerGroupType } from "../../../../../../models/request/mentoring-request";
import PageLoader from "../../../../../components/PageLoader";
import LeaveGroup from "./leaveGroup/LeaveGroup";
import { GoArrowRight } from "react-icons/go";
import SharedPagination from "../../../../../components/Pagination";

const AllMentorshipGroups = () => {
  const navigate = useNavigate();

  const [storedUserId] = useState(sessionStorage.getItem("userId"));
  const [phoneNumber] = useState(sessionStorage.getItem("phoneNumber"));
  const [firstName] = useState(sessionStorage.getItem("firstName"));
  const [lastName] = useState(sessionStorage.getItem("lastName"));
  const userId = parseInt(storedUserId);

  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [pageSize] = useState(6);
  const { data: response, isLoading } = useAllMentorshipGroupsQuery({
    page_index: currentPageIndex,
    page_size: pageSize,
    isEnabled: true,
  });

  const mentorGroup = response?.data;
  const totalPages = response?.totalPages;
  const totalElements = response?.totalElements;

  const [groupData] = useState<registerGroupType>({
    requestedBy: [
      {
        userId: userId,
        phone: phoneNumber,
        firstName: firstName,
        lastName: lastName,
      },
    ],
    groupId: 0,
  });

  const roles = sessionStorage.getItem("roles");
  const allowedRoles = ["Admin", "Super Admin"];
  const hasAllowedRole =
    roles && allowedRoles.some((role) => roles.includes(role));

  if (isLoading) return <PageLoader />;

  return (
    <Row className="my-4">
      <>
        {mentorGroup?.map((group) => (
          <Col key={group.id} className="p-3 m-0" md={4}>
            <Card
              className="border-0 p-2 rounded-3"
              style={{ backgroundColor: "#FAFAFA" }}
            >
              <Card.Body>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {group.members.slice(0, 5).map((member, index) => (
                    <Avatar
                      key={index}
                      name={`${member.firstName} ${member.lastName}`}
                      size="40"
                      round
                      style={{ marginLeft: index !== 0 ? "-15px" : 0 }}
                    />
                  ))}
                  {group.members.length > 5 && (
                    <div style={{ marginLeft: "-15px" }}>
                      <div
                        className="rounded-circle bg-white d-flex align-items-center justify-content-center"
                        style={{
                          width: "40px",
                          height: "40px",
                          fontSize: "14px",
                          fontWeight: "bold",
                          cursor: "pointer",
                        }}
                      >
                        +{group.members.length - 5}
                      </div>
                    </div>
                  )}
                </div>

                <Row className="mt-5">
                  <Col>
                    <Card.Text
                      className="fw-bold mb-0"
                      style={{ fontSize: "16px" }}
                    >
                      {group.name}
                    </Card.Text>
                    <Card.Text
                      className="text-muted mb-0"
                      style={{ fontSize: "14px" }}
                    >
                      {group.members.length} members
                    </Card.Text>
                  </Col>
                  {!hasAllowedRole && (
                    <Col>
                      {group.mentors.some(
                        (mentor) => mentor.user.userId === userId
                      ) ? (
                        <Button
                          className="py-2 mx-1 rounded-5"
                          variant="success"
                          style={{ fontSize: "13px" }}
                        >
                          Registered Mentor
                        </Button>
                      ) : group.members.some(
                          (member) => member.userId === userId
                        ) ? (
                        <LeaveGroup groupId={group.id} />
                      ) : (
                        <Button
                          className="py-2 mx-1 rounded-5"
                          variant="dark"
                          style={{ fontSize: "13px" }}
                          onClick={() =>
                            navigate("/e-mentoring/request-to-join", {
                              state: {
                                groupData: {
                                  ...groupData,
                                  groupId: group.id, // Set groupId to the current group's id
                                },
                              },
                            })
                          }
                        >
                          Request to join <GoArrowRight />
                        </Button>
                      )}
                    </Col>
                  )}
                  {hasAllowedRole && (
                    <>
                      <Col>
                        <Link to={`/e-mentoring/edit-group/${group.id}`}>
                          <Button
                            style={{ fontSize: "14px" }}
                            variant="dark"
                            className="rounded-5"
                          >
                            Edit Group
                          </Button>
                        </Link>
                      </Col>
                      <AssignMentorToGroup groupId={group.id} />
                    </>
                  )}
                  <Link
                    className="text-decoration-none"
                    to={`/e-mentoring/group-details/${group.id}`}
                  >
                    View More
                  </Link>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </>
      <SharedPagination
        currentPage={currentPageIndex + 1}
        onPageChange={(newPage) => setCurrentPageIndex(newPage - 1)}
        totalPages={totalPages}
        pageSize={pageSize}
        totalElements={totalElements}
      />
    </Row>
  );
};

export default AllMentorshipGroups;
