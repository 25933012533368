import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { LoginUserResponse } from "../models/response/app-login";
import {
  LoginUserRequest,
  resetUser,
} from "../models/request/login-user.request";
import { ResetPasswordResponse } from "../models/response/app-reset-password";
import { AppUser } from "../models/response/app-user";
import { BaseResponse } from "../models/response/base-response";
import { assignType, roleType } from "../models/request/create-user.request";
import { AppConstants } from "../core/mocks/app-constants";
import queryString from "query-string";

export const authService = createApi({
  reducerPath: "authService",
  baseQuery: fetchBaseQuery({
    baseUrl: `${AppConstants.authBaseUrl}`,
  }),
  tagTypes: ["Auth"],
  endpoints: (build) => ({
    loginUser: build.mutation<LoginUserResponse[], LoginUserRequest>({
      query: (signInUserRequest) => ({
        url: "/auth/login",
        method: "POST",
        body: signInUserRequest,
      }),
      invalidatesTags: ["Auth"],
    }),
    resetPassword: build.mutation<ResetPasswordResponse, resetUser>({
      query: (resetPasswordRequest) => ({
        url: "/auth/reset-password",
        method: "POST",
        body: resetPasswordRequest,
      }),
      invalidatesTags: ["Auth"],
    }),
    userRoles: build.query<BaseResponse<AppUser[]>, void>({
      query: () => ({
        url: "/roles",
        method: "GET",
      }),
      providesTags: ["Auth"],
    }),
    createUserRoles: build.mutation<BaseResponse<AppUser[]>, roleType>({
      query: (body) => ({
        url: "/roles",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Auth"],
    }),
    getUserWithRoles: build.query<BaseResponse<AppUser[]>, any>({
      query: ({ role_name, params }) => ({
        url: `/roles/users/${role_name}?${queryString.stringify(params)}`,
        method: "GET",
      }),
      providesTags: ["Auth"],
    }),
    assignRoles: build.mutation<BaseResponse<AppUser[]>, assignType>({
      query: (body) => ({
        url: "/roles/assign",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Auth"],
    }),
    revokeRoles: build.mutation<BaseResponse<AppUser[]>, assignType>({
      query: (body) => ({
        url: "/roles/revoke",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Auth"],
    }),
    deleteUserRoles: build.mutation<any, string>({
      query: (roleName) => ({
        url: `/roles/${roleName}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Auth"],
    }),

  }),
});

export const {
  useLoginUserMutation,
  useResetPasswordMutation,
  useUserRolesQuery,
  useCreateUserRolesMutation,
  useGetUserWithRolesQuery,
  useAssignRolesMutation,
  useRevokeRolesMutation,
  useDeleteUserRolesMutation,
} = authService;
