import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import RegionTable from "../data/InterventionsTable";
import { useGetInterventionQuery } from "../../../../../../services/event-service";
import PageLoader from "../../../../../components/PageLoader";
import SharedPagination from "../../../../../components/Pagination";

const InterventionDataTable = ({ searchQuery }) => {
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [pageSize] = useState(10);

  const { data: response, isLoading } = useGetInterventionQuery({
    page: currentPageIndex,
    size: pageSize,
  });

   const intervent = response?.data?.[0]?.data || [];

  const filteredRegionData =
    intervent?.filter((region) =>
      Object.values(region).some((value) =>
        String(value).toLowerCase().includes(searchQuery.toLowerCase())
      )
    ) || [];

  const totalPages = response?.data[0]?.totalPages;
  const totalElements = response?.data[0]?.totalElements;
  console.log("region totalPages", totalPages);
  return (
    <Container>
      <Row>
        <Col>
          <div className="w-100 h-auto">
            {isLoading ? (
              <PageLoader />
            ) : (
              <>
                {filteredRegionData.length === 0 ? (
                  <h3 className="text-black">No Intervention available</h3>
                ) : (
                  <DataGrid
                    rows={filteredRegionData || []}
                    columns={RegionTable}
                    checkboxSelection
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: false,
                      },
                    }}
                    sx={{
                      "& .header--bg": {
                        backgroundColor: "#EBF1FC",
                      },
                      "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: "#EBF1FC",
                        fontSize: "12px",
                      },
                      "& .Mui-checked .MuiSvgIcon-root": {
                        fill: "black",
                        background: "white",
                      },
                      "& .MuiSvgIcon-root": {
                        width: "18px",
                        height: "18px",
                        borderRadius: "4px",
                        color: "#EBEBEB",
                      },
                      "&.MuiDataGrid-root": {
                        border: "none",
                        fontSize: "14px",
                        fontWeight: "400",
                        fontFamily: "Inter",
                        backgroundColor: "white",
                      },

                      "& .MuiDataGrid-footerContainer": {
                        display: "none",
                      },
                    }}
                  />
                )}
                <SharedPagination
                  currentPage={currentPageIndex + 1}
                  onPageChange={(newPage) => setCurrentPageIndex(newPage - 1)}
                  totalPages={totalPages}
                  pageSize={pageSize}
                  totalElements={totalElements}
                />
              </>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default InterventionDataTable;
