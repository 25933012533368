import { Button, Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { useDeleteUserRolesMutation } from "../../../../../../services/auth-service";

const DeleteRole = ({ id,name, show, onClose }) => {
  console.log("Deleting role:", name); // Debugging line
  const [deleteRoleNameMutation] = useDeleteUserRolesMutation();

  const handleDeleteMediaHouse = async () => {
    try {
      // Call the delete user mutation with the user ID
      const response = await deleteRoleNameMutation(name);
      onClose(); // Close the modal after successful deletion
      const { code, message } = response["data"];
      if (code === 200) {
        toast.success(message);
      } else {
        toast.error(message || "Something went wrong");
      }
    } catch (error) {
      console.error("Error deleting user:", error);
      toast.error(error.message);
    }
  };

  return (
    <>
      <Modal show={show} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title className="fs-6">Delete Role</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this role:{name}?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="dark"
            className="users-text rounded-5"
            onClick={onClose}
          >
            Close
          </Button>
          <Button
            className="users-text rounded-5"
            variant="danger"
            onClick={handleDeleteMediaHouse}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteRole;
