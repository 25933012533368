import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AppEvent } from "../models/response/app-event";
import { AppConstants } from "../core/mocks/app-constants";
import {
  BaseResponse,
  EventBaseResponse,
} from "../models/response/base-response";
import {
  bulkCheckinType,
  bulkParticipantType,
  checkinType,
  CreateEvent,
  districtType,
  editDistrictType,
  EditEvent,
  editInterventionType,
  editMediaHouseType,
  editPartnerType,
  editRegionType,
  eventParams,
  EventParticipants,
  expectationParams,
  interventionType,
  mediaHouseType,
  partnerType,
  regionType,
  reviewType,
} from "../models/request/create-event.request";
import queryString from "query-string";

export const eventService = createApi({
  reducerPath: "eventService",
  baseQuery: fetchBaseQuery({
    baseUrl: `${AppConstants.eventBaseUrl}`,
  }),
  tagTypes: ["Events"],
  endpoints: (build) => ({
    createEvent: build.mutation<BaseResponse<any[]>, CreateEvent>({
      query: (body: CreateEvent) => ({
        url: `/events`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Events"],
    }),
    editEvent: build.mutation<EventBaseResponse<AppEvent>, EditEvent>({
      query: (body: EditEvent) => ({
        url: `/events`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["Events"],
    }),
    getEventById: build.query<BaseResponse<AppEvent[]>, number>({
      query: (id) => ({
        url: `/events/${id}`,
        method: "GET",
      }),
      providesTags: ["Events"],
    }),
    deleteEventById: build.mutation<EventBaseResponse<AppEvent>, number>({
      query: (id) => ({
        url: `/events/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Events"],
    }),
    createEventPartner: build.mutation<
      EventBaseResponse<AppEvent>,
      partnerType
    >({
      query: (body: partnerType) => ({
        url: `/event-partners`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Events"],
    }),

    deleteEventPartnerById: build.mutation<EventBaseResponse<AppEvent>, number>(
      {
        query: (id) => ({
          url: `/event-partners/${id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Events"],
      }
    ),

    updateEventPartner: build.mutation<
      EventBaseResponse<AppEvent>,
      editPartnerType
    >({
      query: (body: editPartnerType) => ({
        url: `/event-partners`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["Events"],
    }),
    getEventPartnersList: build.query<
      EventBaseResponse<AppEvent[]>,
      eventParams
    >({
      query: (params) => ({
        url: `/event-partners/list?${queryString.stringify(params)}`,
        method: "GET",
      }),
      providesTags: ["Events"],
    }),
    createRegion: build.mutation<EventBaseResponse<AppEvent>, regionType>({
      query: (body: regionType) => ({
        url: `/regions`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Events"],
    }),

    deleteEventRegionById: build.mutation<EventBaseResponse<AppEvent>, number>({
      query: (regionid) => ({
        url: `/regions/${regionid}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Events"],
    }),

    updateRegion: build.mutation<EventBaseResponse<AppEvent>, editRegionType>({
      query: (body: editRegionType) => ({
        url: `/regions`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["Events"],
    }),
    getEventRegionList: build.query<EventBaseResponse<AppEvent[]>, eventParams>(
      {
        query: (params) => ({
          url: `/regions/list?${queryString.stringify(params)}`,
          method: "GET",
        }),
        providesTags: ["Events"],
      }
    ),
    createDistrict: build.mutation<EventBaseResponse<AppEvent>, districtType>({
      query: (body: districtType) => ({
        url: `/districts`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Events"],
    }),
    deleteEventDistrictById: build.mutation<
      EventBaseResponse<AppEvent>,
      number
    >({
      query: (districtId) => ({
        url: `/districts/${districtId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Events"],
    }),

    updateDistrict: build.mutation<
      EventBaseResponse<AppEvent>,
      editDistrictType
    >({
      query: (body: editDistrictType) => ({
        url: `/districts`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["Events"],
    }),
    getEventDistrictList: build.query<
      EventBaseResponse<AppEvent[]>,
      eventParams
    >({
      query: (params) => ({
        url: `/districts/list?${queryString.stringify(params)}`,
        method: "GET",
      }),
      providesTags: ["Events"],
    }),
    getEventMediaHouseList: build.query<
      EventBaseResponse<AppEvent[]>,
      eventParams
    >({
      query: (params) => ({
        url: `/event-media-houses/list?${queryString.stringify(params)}`,
        method: "GET",
      }),
      providesTags: ["Events"],
    }),
    createEventMediaHouse: build.mutation<
      EventBaseResponse<AppEvent[]>,
      mediaHouseType
    >({
      query: (body: mediaHouseType) => ({
        url: `/event-media-houses`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Events"],
    }),
    updateEventMediaHouse: build.mutation<
      EventBaseResponse<AppEvent[]>,
      editMediaHouseType
    >({
      query: (body: editMediaHouseType) => ({
        url: `/event-media-houses`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["Events"],
    }),
    deleteEventMediaHouse: build.mutation<
      EventBaseResponse<AppEvent[]>,
      number
    >({
      query: (id) => ({
        url: `/event-media-houses/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Events"],
    }),
    getEventParticipantsList: build.query<
      EventBaseResponse<AppEvent[]>,
      eventParams
    >({
      query: (params) => ({
        url: `/event-participants/list?${queryString.stringify(params)}`,
        method: "GET",
      }),
      providesTags: ["Events"],
    }),
    getDistrictByRegionId: build.query<BaseResponse<AppEvent[]>, number>({
      query: (regionId) => ({
        url: `/districts/list/${regionId}`,
        method: "GET",
      }),
      providesTags: ["Events"],
    }),
    createEventParticipants: build.mutation<
      EventBaseResponse<AppEvent>,
      EventParticipants
    >({
      query: (body: EventParticipants) => ({
        url: `/event-participants`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Events"],
    }),
    createBulkParticipants: build.mutation<
      EventBaseResponse<AppEvent>,
      bulkParticipantType
    >({
      query: (body: bulkParticipantType) => ({
        url: `/event-participants/bulk`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Events"],
    }),
    deleteEventParticipants: build.mutation<
      EventBaseResponse<AppEvent>,
      { eventId: number; participantId: number }
    >({
      query: ({ eventId, participantId }) => ({
        url: `/event-participants/${eventId}/${participantId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Events"],
    }),
    getFilteringEvent: build.query<EventBaseResponse<AppEvent[]>, eventParams>({
      query: (params) => ({
        url: `/events/filter?${queryString.stringify(params)}`,
        method: "GET",
      }),
      providesTags: ["Events"],
    }),

    getRegisteredEventList: build.query<EventBaseResponse<AppEvent[]>, number>({
      query: (userId) => ({
        url: `/events/list/event/${userId}`,
        method: "GET",
      }),
      providesTags: ["Events"],
    }),
    eventCheckIn: build.mutation<EventBaseResponse<AppEvent>, checkinType>({
      query: (body: checkinType) => ({
        url: `/event-check-in`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Events"],
    }),
    bulkEventCheckIn: build.mutation<
      EventBaseResponse<AppEvent>,
      bulkCheckinType
    >({
      query: (body: bulkCheckinType) => ({
        url: "/event-check-in/bulk",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Events"],
    }),

    getExpectationsByPhoneNumber: build.query<
      EventBaseResponse<AppEvent[]>,
      expectationParams
    >({
      query: (params) => ({
        url: `/event-expectation/filter?${queryString.stringify(params)}`,
        method: "GET",
      }),
      providesTags: ["Events"],
    }),

    getReviewsByPhoneNumber: build.query<
      EventBaseResponse<AppEvent[]>,
      expectationParams
    >({
      query: (params) => ({
        url: `/event-review/filter?${queryString.stringify(params)}`,
        method: "GET",
      }),
      providesTags: ["Events"],
    }),
    createExpectations: build.mutation<EventBaseResponse<AppEvent>, reviewType>(
      {
        query: (body: reviewType) => ({
          url: `/event-expectation`,
          method: "POST",
          body: body,
        }),
        invalidatesTags: ["Events"],
      }
    ),
    createReviews: build.mutation<EventBaseResponse<AppEvent>, reviewType>({
      query: (body: reviewType) => ({
        url: `/event-review`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Events"],
    }),
    getAllReviews: build.query<EventBaseResponse<AppEvent>, eventParams>({
      query: (params) => ({
        url: `/event-review/list${queryString.stringify(params)}`,
        method: "GET",
      }),
      providesTags: ["Events"],
    }),
    createIntervention: build.mutation<
      EventBaseResponse<AppEvent>,
      interventionType
    >({
      query: (body: interventionType) => ({
        url: "/event-intervention",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Events"],
    }),
    getIntervention: build.query<EventBaseResponse<AppEvent[]>, eventParams>({
      query: (params) => ({
        url: `/event-intervention?${queryString.stringify(params)}`,
        method: "GET",
      }),
      providesTags: ["Events"],
    }),
    editIntervention: build.mutation<
      EventBaseResponse<AppEvent>,
      editInterventionType
    >({
      query: (body: editInterventionType) => ({
        url: "/event-intervention",
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["Events"],
    }),
    deleteIntervention: build.mutation<EventBaseResponse<AppEvent>, number>({
      query: (id) => ({
        url: `/event-intervention/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Events"],
    }),
  }),
});

export const {
  useCreateEventMutation,
  useGetEventByIdQuery,
  useDeleteEventByIdMutation,
  useEditEventMutation,
  useGetEventPartnersListQuery,
  useCreateEventPartnerMutation,
  useUpdateEventPartnerMutation,
  useDeleteEventPartnerByIdMutation,
  useCreateRegionMutation,
  useGetEventRegionListQuery,
  useDeleteEventRegionByIdMutation,
  useUpdateRegionMutation,
  useCreateDistrictMutation,
  useGetEventDistrictListQuery,
  useUpdateDistrictMutation,
  useDeleteEventDistrictByIdMutation,
  useGetEventMediaHouseListQuery,
  useCreateEventMediaHouseMutation,
  useUpdateEventMediaHouseMutation,
  useDeleteEventMediaHouseMutation,
  useGetEventParticipantsListQuery,
  useGetDistrictByRegionIdQuery,
  useCreateEventParticipantsMutation,
  useDeleteEventParticipantsMutation,
  useGetFilteringEventQuery,
  useGetRegisteredEventListQuery,
  useEventCheckInMutation,
  useBulkEventCheckInMutation,
  useGetExpectationsByPhoneNumberQuery,
  useCreateExpectationsMutation,
  useCreateReviewsMutation,
  useGetAllReviewsQuery,
  useGetReviewsByPhoneNumberQuery,
  useCreateInterventionMutation,
  useGetInterventionQuery,
  useEditInterventionMutation,
  useDeleteInterventionMutation,
  useCreateBulkParticipantsMutation,
} = eventService;
