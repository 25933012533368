import { useCallback, useEffect, useState } from "react";
import { Button, Card, Form, Modal, Row, Col } from "react-bootstrap";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { TbUsersPlus } from "react-icons/tb";
import { MultiSelect } from "react-multi-select-component";
import { useNavigate } from "react-router-dom";
import { useCoordinatorAddUserMutation } from "../../../../../../services/user-service";
import { CoordinatorCreateUser } from "../../../../../../models/request/create-user.request";
import { useCreateFormAnswerMutation } from "../../../../../../services/form-answers-service";
import { FormAnswerRequest } from "../../../../../../models/response/FormAnswerDTO";
import { useFilterFormDropdownsMutation } from "../../../../../../services/form-dropdown-suggestion";

const CreateUserModal = () => {
  const navigate = useNavigate();
  const [storedUserId] = useState(sessionStorage.getItem("userId"));
  const userId = parseInt(storedUserId);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedDisabilityOptions, setSelectedDisabilityOptions] = useState(
    []
  );
  const [springboadEmpty, setSpringboardEmpty] = useState<boolean>(false);
  const [disabilityStatusEmpty, setDisabilityStatusEmpty] =
    useState<boolean>(false);

  const [onboardUser] = useCoordinatorAddUserMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
  } = useForm<CoordinatorCreateUser>({
    mode: "all",
    defaultValues: {
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      phone: "",
      gender: "",
      whatsappNumber: "",
      age: "",
      springboardCategory: [],
      disabilityStatus: [],
      highestLevelOfEducation: "",
      onboardedBy: userId,
    },
  });

  const options = [
    { value: "Employer", label: "Employer" },
    { value: "Job Seeker(Students)", label: "Student" },
    { value: "Job Seeker(Unemployed)", label: "Unemployed" },
    { value: "Job Seeker(Employee)", label: "Employee" },
    {
      value: "Support Partner(Volunteer)",
      label: "Volunteer",
    },
    { value: "Support Partner(Vendor)", label: "Vendor" },
    {
      value: "Support Partner(Facilitator)",
      label: "Facilitator",
    },
    { value: "Stakeholder", label: "Community Stakeholder" },
    {
      value: "Association, Group Or Partner",
      label: "Association or Group",
    },
    {
      value: "Mentor",
      label: "Mentor",
    },
    {
      value: "Counsellor",
      label: "Counsellor",
    },
  ];

  const disabiltyOptions = [
    { value: "None", label: "None" },
    { value: "Blind", label: "Blind" },
    { value: "Deaf", label: "Deaf" },
    { value: "Physically Challenged", label: "Physically Challenged" },
    { value: "Speech Impaired", label: "Speech Impaired" },
    { value: "Albino", label: "Albino" },
  ];

  const handleCreate = (inputValue) => {
    const newOption = { value: inputValue.toLowerCase(), label: inputValue };
    setSelectedOptions([...selectedOptions, newOption]);
  };

  const handleDisabilty = (inputValue) => {
    const newOption = { value: inputValue.toLowerCase(), label: inputValue };
    setSelectedDisabilityOptions([...selectedDisabilityOptions, newOption]);
  };

  const handleChange = (selectedValues) => {
    setSelectedOptions(selectedValues);
    setSpringboardEmpty(false);
  };
  const handleChangeDisabilty = (selectedValues) => {
    const isNoneSelected = selectedValues.some(
      (option) => option.value === "None"
    );
    if (isNoneSelected) {
      setSelectedDisabilityOptions([{ value: "None", label: "None" }]);
    } else {
      setSelectedDisabilityOptions(selectedValues);
    }
    setDisabilityStatusEmpty(false); // Reset the empty state
  };

  const onSubmit: SubmitHandler<CoordinatorCreateUser> = async (formData) => {
    try {
      if (selectedOptions.length === 0) {
        console.error("Springboard category is required");
        setSpringboardEmpty(true);
      }
      if (selectedDisabilityOptions.length === 0) {
        console.error("Disability Status required");
        setDisabilityStatusEmpty(true);
      }

      const finalResponse = {
        ...formData,
        disabilityStatus: selectedDisabilityOptions.map((value) => value.label),
        springboardCategory: selectedOptions.map((value) => value.label),
      };

      const response = await onboardUser(finalResponse);
      const { code, message, data } = response["data"];
      console.log("API Response:", response);

      if (code === 201) {
        const { id } = data;
        // sessionStorage.setItem("registrationId", id);
        toast.success(message);
        setTimeout(() => {
          navigate(`/auth/signup-registration-1`, {
            state: { finalResponse, id },
          });
        }, 2000);
        return id;
      } else {
        toast.error(message || "Something went wrong");
      }
    } catch (error) {
      console.error("Error in API call:", error);
      toast(error.message);
    }
  };

  const [formState, setFormState] = useState({
    region: "",
    district: "",
    communities: "",
    town: "",
  });
  const [regions, setRegions] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [communities, setCommunities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDistrictDisabled, setIsDistrictDisabled] = useState(true);
  const [isCommunitiesDisabled, setIsCommunitiesDisabled] = useState(true);
  const [isTownDisabled, setIsTownDisabled] = useState(true);

  const [createUserForm] = useCreateFormAnswerMutation<FormAnswerRequest>();
  const [filterFormDropdown] = useFilterFormDropdownsMutation();

  const fetchDropdownSuggestions = useCallback(
    async (questionId, setSuggestions, bindValue = null) => {
      setLoading(true);
      try {
        const response = await filterFormDropdown({
          bind_value: bindValue,
          question_id: questionId,
        }).unwrap();
        setSuggestions(response.data || []);
      } catch (error) {
        console.error(
          `Failed to fetch suggestions for question ${questionId}:`,
          error
        );
      } finally {
        setLoading(false);
      }
    },
    [filterFormDropdown]
  );

  useEffect(() => {
    fetchDropdownSuggestions(157, setRegions);
  }, [fetchDropdownSuggestions]);

  useEffect(() => {
    if (formState.region) {
      fetchDropdownSuggestions(160, setDistricts, formState.region);
    }
  }, [formState.region, fetchDropdownSuggestions]);

  useEffect(() => {
    if (formState.district) {
      fetchDropdownSuggestions(159, setCommunities, formState.district);
    }
  }, [formState.district, fetchDropdownSuggestions]);

  const handleLocationChange = (e) => {
    const { name, value } = e.target;
    setFormState((prev) => ({ ...prev, [name]: value }));

    if (name === "region") {
      setIsDistrictDisabled(false);
      setFormState((prev) => ({
        ...prev,
        district: "",
        communities: "",
        town: "",
      }));
      setIsCommunitiesDisabled(true);
      setIsTownDisabled(true);
    }

    if (name === "district") {
      setIsCommunitiesDisabled(false);
      setFormState((prev) => ({ ...prev, communities: "", town: "" }));
      setIsTownDisabled(true);
    }

    if (name === "communities") {
      setIsTownDisabled(false);
      setFormState((prev) => ({ ...prev, town: "" }));
    }
  };

  const handleLocationSubmit = async (userid) => {
    setLoading(true);
    const payload = {
      formId: 13,
      answeredBy: userid,
      answers: [
        { formQuestionId: 157, answerProvided: formState.region },
        { formQuestionId: 158, answerProvided: formState.town },
        { formQuestionId: 160, answerProvided: formState.district },
        { formQuestionId: 159, answerProvided: formState.communities },
      ],
    };

    try {
      const response = await createUserForm(payload);
      const { code, message } = response["data"];
      if (code === 201) {
        toast.success(message);
      } else {
        toast.error(message || "Something went wrong");
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const onSubmitFinal = async (data) => {
    const userid = await onSubmit(data);
    await handleLocationSubmit(userid);
  };

  return (
    <>
      <Button
        className="rounded-5 text-black border-0"
        style={{ fontSize: "12px", background: "rgba(25, 22, 29, 0.06)" }}
        onClick={handleShow}
      >
        Create new user <TbUsersPlus />
      </Button>
      <Modal show={show} size="lg" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="fs-6">Create User</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmitFinal)} className="g-3 ">
          <Modal.Body className="m-0 p-0">
            <Card className="border-0  rounded-3">
              <Card.Body className="p-4">
                <Row>
                  <Col className="px-1">
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Control
                        type="text"
                        placeholder="First name"
                        size="lg"
                        {...register("firstName", {
                          required: {
                            value: true,
                            message: "First name is required",
                          },
                          pattern: {
                            value: /^[A-Za-z0-9-]+$/,
                            message: "Please enter only alphanumeric",
                          },
                        })}
                      />
                      <p className="text-danger fs-6">
                        {errors.firstName?.message}
                      </p>
                    </Form.Group>
                  </Col>
                  <Col className="px-1">
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Control
                        type="text"
                        placeholder="Middle name"
                        size="lg"
                        {...register("middleName", {
                          pattern: {
                            value: /^[A-Za-z0-9-]+$/,
                            message: "Please enter only alphanumeric",
                          },
                        })}
                      />
                      <p className="text-danger">
                        {errors.middleName?.message}
                      </p>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className="px-1">
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Control
                        type="text"
                        placeholder="Last name"
                        size="lg"
                        {...register("lastName", {
                          required: {
                            value: true,
                            message: "Lastname is required",
                          },
                          pattern: {
                            value: /^[A-Za-z0-9-]+$/,
                            message: "Please enter only alphanumeric",
                          },
                        })}
                      />

                      <p className="text-danger">{errors.lastName?.message}</p>
                    </Form.Group>
                  </Col>
                  <Col className="px-1">
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Control
                        type="text"
                        placeholder="Email"
                        size="lg"
                        {...register("email", {
                          required: {
                            value: true,
                            message: "Email is required",
                          },

                          pattern: {
                            value:
                              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                            message: "Email format is invalid",
                          },
                        })}
                      />
                      <p className="text-danger">{errors.email?.message}</p>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className="px-1">
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Control
                        type="text"
                        placeholder="Mobile Number"
                        size="lg"
                        {...register("phone", {
                          required: {
                            value: true,
                            message: "Phone number is required",
                          },
                          pattern: {
                            value: /^(233|0)\d{9}$/,
                            message: "Phone number must be a valid number",
                          },
                        })}
                      />
                      <p className="text-danger">{errors.phone?.message}</p>
                    </Form.Group>
                  </Col>

                  <Col className="px-1">
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Control
                        type="text"
                        placeholder="WhatsApp Number"
                        size="lg"
                        {...register("whatsappNumber", {
                          pattern: {
                            value: /^(233|0)\d{9}$/,
                            message: "Invalid WhatsApp number",
                          },
                        })}
                      />
                      <p className="text-danger">
                        {errors.whatsappNumber?.message}
                      </p>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className="px-1">
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Select
                        className="text-muted"
                        size="lg"
                        {...register("age", {
                          required: {
                            value: true,
                            message: "Age is required",
                          },
                        })}
                        defaultValue=""
                      >
                        <option value="" disabled>
                          Age
                        </option>
                        <option value="AGE_BELOW_15">Below 15yrs</option>
                        <option value="AGE_15_17">15 – 17 yrs</option>
                        <option value="AGE_18_24">18 – 24 yrs</option>
                        <option value="AGE_25_30">25 – 30 yrs </option>
                        <option value="AGE_31_35">31 – 35 years </option>
                        <option value="AGE_36_PLUS">36+ years </option>
                      </Form.Select>
                      <p className="text-danger">{errors.age?.message}</p>
                    </Form.Group>
                  </Col>
                  <Col className="px-1">
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Select
                        className="text-muted"
                        size="lg"
                        {...register("gender", {
                          required: {
                            value: true,
                            message: "Gender is required",
                          },
                        })}
                        defaultValue=""
                      >
                        <option value="" disabled>
                          Gender
                        </option>
                        <option value="M">Male</option>
                        <option value="F">Female</option>
                      </Form.Select>
                      <p className="text-danger">{errors.gender?.message}</p>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className="px-1">
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Select
                        className="text-muted"
                        size="lg"
                        {...register("highestLevelOfEducation", {
                          required: {
                            value: true,
                            message: "Educational level is required",
                          },
                        })}
                      >
                        <option value="" disabled>
                          Highest Level of Eduction
                        </option>
                        <option value="No Formal Education">
                          No Formal Education
                        </option>
                        <option value="Primary">Primary</option>
                        <option value="Junior High School (JHS)">
                          Junior High School (JHS)
                        </option>
                        <option value="Senior High School (SHS)">
                          Senior High School (SHS)
                        </option>
                        <option value="TVET (Secondary)">
                          TVET (Secondary)
                        </option>
                        <option value="TVET (Tertiary)">TVET (Tertiary)</option>
                        <option value="HND/Other Diplomas">
                          HND/Other Diplomas
                        </option>
                        <option value="First Degree / Bachelors">
                          First Degree / Bachelors
                        </option>
                        <option value="Master’s Degree">Master’s Degree</option>
                        <option value="Doctorate Degree">
                          Doctorate Degree
                        </option>
                      </Form.Select>
                      <p className="text-danger">
                        {errors.highestLevelOfEducation?.message}
                      </p>
                    </Form.Group>
                  </Col>
                  <Col className="px-1">
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Controller
                        name="springboardCategory"
                        control={control}
                        render={({ field }) => (
                          <div>
                            <MultiSelect
                              options={options}
                              className="react-select-container z-3"
                              value={selectedOptions}
                              onChange={handleChange}
                              onCreateOption={handleCreate}
                              labelledBy="Springboard Category"
                              overrideStrings={{
                                selectSomeItems: "Springboard Category",
                              }}
                              hasSelectAll={false}
                            />
                            {springboadEmpty ? (
                              <p className="text-danger">
                                Springboard Category is required
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        )}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  {" "}
                  <Col className="px-1">
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Controller
                        name="disabilityStatus"
                        control={control}
                        render={({ field }) => (
                          <div>
                            <MultiSelect
                              options={disabiltyOptions}
                              value={selectedDisabilityOptions}
                              onChange={handleChangeDisabilty}
                              labelledBy="Disability Status"
                              onCreateOption={handleDisabilty}
                              className="react-select-container z-3"
                              overrideStrings={{
                                selectSomeItems: "Disability Status",
                              }}
                              hasSelectAll={false}
                            />
                            {/* Updated condition for error message */}
                            {disabilityStatusEmpty ? (
                              <p className="text-danger">
                                Disability Status is required
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        )}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="pt-4">
                  <Col className="px-1">
                    {" "}
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Select
                        className="text-muted"
                        size="lg"
                        name="region"
                        value={formState.region}
                        onChange={handleLocationChange}
                        disabled={loading}
                      >
                        <option value="" disabled>
                          Select Region
                        </option>
                        {regions.map((region) => (
                          <option key={region.id} value={region.value}>
                            {region.value}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col className="px-1">
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Select
                        className="text-muted"
                        size="lg"
                        name="district"
                        value={formState.district}
                        onChange={handleLocationChange}
                        disabled={isDistrictDisabled || loading}
                      >
                        <option value="" disabled>
                          Select District
                        </option>
                        {districts.map((district) => (
                          <option key={district.id} value={district.value}>
                            {district.value}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className="px-1">
                    {" "}
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Select
                        className="text-muted"
                        size="lg"
                        name="communities"
                        value={formState.communities}
                        onChange={handleLocationChange}
                        disabled={isCommunitiesDisabled || loading}
                      >
                        <option value="" disabled>
                          Select Communities
                        </option>
                        {communities.map((community) => (
                          <option key={community.id} value={community.value}>
                            {community.value}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col className="p-1">
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Control
                        type="text"
                        placeholder="Town"
                        size="lg"
                        name="town"
                        value={formState.town}
                        onChange={handleLocationChange}
                        disabled={isTownDisabled || loading}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="dark"
              className="users-text rounded-5"
              onClick={handleClose}
            >
              Close
            </Button>
            <Button
              type="submit"
              className="users-text rounded-5"
              variant="success"
            >
              Create User
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default CreateUserModal;
