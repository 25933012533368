import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import Select from "react-select";
import { useCreateCounsellorMutation } from "../../../../../../services/counselling-service";
import { counsellorRequest } from "../../../../../../models/request/counselling-request";
import { useGetUsersListFilterQuery } from "../../../../../../services/user-service";

const AddMentor = () => {
  const [show, setShow] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [pageIndex, setPageIndex] = useState(0);
  const [userOptions, setUserOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [mentor, setMentor] = useState<counsellorRequest>({
    userId: 0,
    phone: "",
    firstName: "",
    role: "MENTOR",
    lastName: "",
  });

  const handleClose = () => {
    setShow(false);
    // Reset fields when the modal is closed
    setSelectedUser(null);
    setMentor({
      userId: 0,
      phone: "",
      firstName: "",
      role: "MENTOR",
      lastName: "",
    });
    setSearchTerm(""); // Reset search term
    setUserOptions([]); // Clear user options
  };
  const handleShow = () => setShow(true);
  const [createCounsellor] = useCreateCounsellorMutation();

  const { data: userResponse, isLoading } = useGetUsersListFilterQuery({
    page_index: pageIndex,
    size: 50,
    ...(searchTerm && { search: searchTerm }),
  });

  useEffect(() => {
    if (userResponse?.data) {
      const newOptions = userResponse.data.map((user) => ({
        value: user.phone,
        label: `${user.firstName} ${user.lastName}`,
        phone: user.phone,
        firstName: user.firstName,
        lastName: user.lastName,
      }));
      setUserOptions((prevOptions) => {
        // Avoid duplicate users when loading more pages
        if (pageIndex === 0) return newOptions;
        return [...prevOptions, ...newOptions];
      });
    }
  }, [userResponse, pageIndex]);

  const handleUserSelectChange = (selectedOption) => {
    setSelectedUser(selectedOption);
    setMentor({
      userId: selectedOption.value,
      phone: selectedOption.phone,
      firstName: selectedOption.firstName,
      lastName: selectedOption.lastName,
      role: "MENTOR",
    });
  };

  const loadMoreUsers = () => {
    if (!isLoading) {
      setPageIndex((prevPageIndex) => prevPageIndex + 1);
    }
  };

  const handleInputChange = (inputValue) => {
    setSearchTerm(inputValue);
    setPageIndex(0); // Reset page index for new search
  };

  const handleCreateMentor = async (e) => {
    e.preventDefault();
    try {
      const response = await createCounsellor(mentor);
      const { code, message } = response["data"];
      if (code === 0) {
        toast.success(message);
        // Reset the form fields
        setSelectedUser(null);
        setMentor({
          userId: 0,
          phone: "",
          firstName: "",
          lastName: "",
          role: "MENTOR",
        });
      } else {
        toast.error(message || "Something went wrong");
      }
      handleClose();
    } catch (error) {
      toast.error(error.message);
      console.error("Error creating counsellor:", error);
    }
  };

  return (
    <>
      <Button
        className="py-2 mx-1 rounded-5 "
        variant="dark"
        style={{ fontSize: "14px" }}
        onClick={handleShow}
      >
        Add Mentor +
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="fs-6">Add Mentor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label style={{ fontSize: "14px" }}>User</Form.Label>
              <Select
                className="p-2"
                options={userOptions}
                onChange={handleUserSelectChange}
                value={selectedUser}
                isLoading={isLoading}
                isDisabled={isLoading}
                onMenuScrollToBottom={loadMoreUsers}
                onInputChange={handleInputChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="rounded-5"
            variant="secondary"
            onClick={handleClose}
            style={{ fontSize: "14px" }}
          >
            Close
          </Button>
          <Button
            style={{ fontSize: "14px" }}
            className="rounded-5"
            variant="primary"
            onClick={handleCreateMentor}
            disabled={!selectedUser}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddMentor;
