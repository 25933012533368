import { GridColDef } from "@mui/x-data-grid";
import { Button, Image } from "react-bootstrap";
import { RiDeleteBin6Line } from "react-icons/ri";

const UsersTableComponent = (
  handleViewUser,
  handleEditUser,
  handleDeleteUser
) => {
  const roles = sessionStorage.getItem("roles");
  const allowedRoles = [
    "Admin",
    "Super Admin",
    "Coordinator",
    "Call Center Manager",
  ];
  const hasAllowedRole =
    roles && allowedRoles.some((role) => roles.includes(role));
  const isCoordinator = roles && roles.includes("Coordinator");

  const UsersTable: GridColDef[] = [
    {
      field: "fullName",
      headerName: "Full Name",
      headerClassName: "header--bg",
      flex: 1,
      renderCell: (params: any) => (
        <>
          <Image
            src={`https://ui-avatars.com/api/?name=${params?.row?.firstName}+${params?.row?.lastName}&background=random&size=32`}
            alt="Avatar"
            roundedCircle
            className="me-2"
          />
          {params?.row?.firstName || ""} {params?.row?.lastName || ""}
        </>
      ),
    },
    {
      field: "phone",
      headerName: "Phone Number",
      flex: 1,
      headerClassName: "header--bg",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      headerClassName: "header--bg",
    },
    {
      field: "gender",
      headerName: "Gender",
      headerClassName: "header--bg",
      valueGetter: (params: any) =>
        params?.row?.gender === "M" ? "Male" : "Female",
    },
    {
      field: "springboardCategory",
      headerName: "Category",
      flex: 1,
      headerClassName: "header--bg",
      valueGetter: (params: any) =>
        params?.value ? (params?.value as string[]).join(", ") : "",
    },
    {
      field: "registrationChannel",
      headerName: "Registration Channel",
      flex: 1,
      headerClassName: "header--bg",
    },
    {
      field: "createdOn",
      headerName: "Date Created",
      flex: 1,
      headerClassName: "header--bg",
      valueFormatter: (params: any) =>
        new Date(params?.value).toLocaleDateString(),
    },
    {
      field: "actions",
      headerName: "Actions",
      headerClassName: "header--bg",
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <div>
          {hasAllowedRole && (
            <Button
              variant="light"
              style={{ fontSize: "12px", background: "rgba(25, 22, 29, 0.06)" }}
              className="rounded-5"
              onClick={() => handleEditUser(params.row.id, params.row)}
            >
              Edit
            </Button>
          )}

          <Button
            variant="light"
            className="rounded-5 "
            style={{ fontSize: "12px", background: "rgba(25, 22, 29, 0.06)" }}
            onClick={() => handleViewUser(params.row.id, params.row)}
          >
            View details ...
          </Button>

          {!isCoordinator && (
            <Button variant="transparent" className="m-0 p-0 bg-transparent border-0" onClick={() => handleDeleteUser(params.row.id)}>
              <RiDeleteBin6Line
                className="text-danger"
                style={{ fontSize: "20px"}}
              />
            </Button>
          )}
        </div>
      ),
    },
  ];

  return UsersTable;
};

export default UsersTableComponent;
