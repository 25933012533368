import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AppConstants } from "../core/mocks/app-constants";
import { BaseResponse } from "../models/response/base-response";
import {
  CounsellingParams,
  CounsellorAddSlot,
  assignCounsellorRequest,
  availabilityType,
  cancelType,
  counsellingType,
  counsellingTypeParams,
  counsellorRequest,
  editCounsellingTypeParams,
  rescheduleRequest,
  sendFeedbackRequest,
  slotRequest,
  slotTimeRequest,
} from "../models/request/counselling-request";
import queryString from "query-string";

export const counsellingService = createApi({
  reducerPath: "counsellingService",
  baseQuery: fetchBaseQuery({
    baseUrl: `${AppConstants.ecounsellingBaseUrl}`,
  }),
  tagTypes: ["counselling"],
  endpoints: (build) => ({
    createCounSelling: build.mutation<BaseResponse<any>, counsellingType>({
      query: (body: counsellingType) => ({
        url: "/counselling",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["counselling"],
    }),
    getCounsellingByUserID: build.query<BaseResponse<any>, number>({
      query: (userId) => ({
        url: `/counselling?user_id=${userId}`,
        method: "GET",
      }),
      providesTags: ["counselling"],
    }),
    getUnassignedCounsellingList: build.query<
      BaseResponse<any>,
      CounsellingParams
    >({
      query: (params) => ({
        url: `/counselling/requests/unassigned?${queryString.stringify(
          params
        )}`,
        method: "GET",
      }),
      providesTags: ["counselling"],
    }),
    getCounsellorList: build.query<BaseResponse<any>, CounsellingParams>({
      query: (params) => ({
        url: `/counselling/counsellor?${queryString.stringify(params)}`,
        method: "GET",
      }),
      providesTags: ["counselling"],
    }),
    createCounsellor: build.mutation<BaseResponse<any>, counsellorRequest>({
      query: (body: counsellorRequest) => ({
        url: "/counselling/counsellor",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["counselling"],
    }),
    assignCounsellor: build.mutation<
      BaseResponse<any>,
      assignCounsellorRequest
    >({
      query: (body: assignCounsellorRequest) => ({
        url: "/counselling/requests/confirmation",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["counselling"],
    }),
    CancelCounsell: build.mutation<BaseResponse<any>, cancelType>({
      query: (body: cancelType) => ({
        url: `/counselling/request/cancel`,
        method: "PATCH",
        body: body,
      }),
      invalidatesTags: ["counselling"],
    }),
    checkAvailability: build.mutation<BaseResponse<any>, availabilityType>({
      query: (body: availabilityType) => ({
        url: `/counselling/counsellor/availability`,
        method: "PATCH",
        body: body,
      }),
      invalidatesTags: ["counselling"],
    }),
    rescheduleCounsell: build.mutation<BaseResponse<any>, rescheduleRequest>({
      query: (body: rescheduleRequest) => ({
        url: "/counselling/request/reschedule",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["counselling"],
    }),
    sendFeedback: build.mutation<BaseResponse<any>, sendFeedbackRequest>({
      query: (body: sendFeedbackRequest) => ({
        url: "/counselling/feedbacks",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["counselling"],
    }),
    getAssignedCounsellingList: build.query<
      BaseResponse<any>,
      CounsellingParams
    >({
      query: (params) => ({
        url: `/counselling/requests/assigned?${queryString.stringify(params)}`,
        method: "GET",
      }),
      providesTags: ["counselling"],
    }),
    availabilitySlotTime: build.mutation<BaseResponse<any>, slotTimeRequest>({
      query: (body: slotTimeRequest) => ({
        url: "/availability-slot-time",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["counselling"],
    }),
    availabilitySlot: build.mutation<BaseResponse<any>, slotRequest>({
      query: (body: slotRequest) => ({
        url: "/availability-slot",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["counselling"],
    }),
    counsellorSlot: build.mutation<BaseResponse<any>, CounsellorAddSlot>({
      query: (body: CounsellorAddSlot) => ({
        url: "/counselling/counsellor/add-slot",
        method: "PATCH",
        body: body,
      }),
      invalidatesTags: ["counselling"],
    }),

    counsellingTypeList: build.query<BaseResponse<any>, CounsellingParams>({
      query: (params) => ({
        url: `/counselling-type/list?${queryString.stringify(params)}`,
        method: "GET",
      }),
      providesTags: ["counselling"],
    }),
    createCounsellingType: build.mutation<
      BaseResponse<any>,
      counsellingTypeParams
    >({
      query: (body: counsellingTypeParams) => ({
        url: "/counselling-type",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["counselling"],
    }),
    editCounsellingType: build.mutation<
      BaseResponse<any>,
      editCounsellingTypeParams
    >({
      query: (body: editCounsellingTypeParams) => ({
        url: "/counselling-type",
        method: "PATCH",
        body: body,
      }),
      invalidatesTags: ["counselling"],
    }),
    deleteCounsellor: build.mutation<BaseResponse<any>, number>({
      query: (userId) => ({
        url: `/counselling/counsellor/${userId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["counselling"],
    }),
  }),
});

export const {
  useCreateCounSellingMutation,
  useGetCounsellingByUserIDQuery,
  useGetUnassignedCounsellingListQuery,
  useGetCounsellorListQuery,
  useCreateCounsellorMutation,
  useAssignCounsellorMutation,
  useCancelCounsellMutation,
  useRescheduleCounsellMutation,
  useGetAssignedCounsellingListQuery,
  useSendFeedbackMutation,
  useCheckAvailabilityMutation,
  useAvailabilitySlotTimeMutation,
  useAvailabilitySlotMutation,
  useCounsellorSlotMutation,
  useCounsellingTypeListQuery,
  useCreateCounsellingTypeMutation,
  useEditCounsellingTypeMutation,
  useDeleteCounsellorMutation,
} = counsellingService;
