import { ChangeEvent, useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  FloatingLabel,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import toast from "react-hot-toast";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { useUpdateUserByIDMutation } from "../../../../../../services/user-service";
import { editUserType } from "../../../../../../models/request/create-user.request";
import {
  useCreateFormAnswerMutation,
  useGetUserLocationQuery,
  useUpdateUserLocationMutation,
} from "../../../../../../services/form-answers-service";
import { useFilterFormDropdownsMutation } from "../../../../../../services/form-dropdown-suggestion";
import { FormAnswerRequest } from "../../../../../../models/response/FormAnswerDTO";

const EditUserModal = ({ userId, userData, show, onClose }) => {
  const navigate = useNavigate();

  console.log("userId", userId);

  const [editUserData, setEditUserData] = useState<editUserType>({
    id: userData?.id,
    firstName: userData?.firstName,
    middleName: userData?.middleName,
    lastName: userData?.lastName,
    email: userData?.email,
    phone: userData?.phone,
    whatsappNumber: userData?.whatsappNumber,
    gender: userData?.gender,
    age: userData?.age,
    highestLevelOfEducation: userData?.highestLevelOfEducation,
    springboardCategory: Array.isArray(userData?.springboardCategory)
      ? userData.springboardCategory
      : [],
    disabilityStatus: Array.isArray(userData?.disabilityStatus)
      ? userData.disabilityStatus
      : [],
  });

  const springboardCategoryOptions = [
    { value: "Employer", label: "Employer" },
    { value: "Job Seeker(Students)", label: "Student" },
    { value: "Job Seeker(Unemployed)", label: "Unemployed" },
    { value: "Job Seeker(Employee)", label: "Employee" },
    {
      value: "Support Partner(Volunteer)",
      label: "Volunteer",
    },
    { value: "Support Partner(Vendor)", label: "Vendor" },
    {
      value: "Support Partner(Facilitator)",
      label: "Facilitator",
    },
    { value: "Stakeholder", label: "Community Stakeholder" },
    {
      value: "Association, Group Or Partner",
      label: "Association or Group",
    },
    {
      value: "Mentor",
      label: "Mentor",
    },
    {
      value: "Counsellor",
      label: "Counsellor",
    },
  ];

  const disabiltyOptions = [
    { value: "None", label: "None" },
    { value: "Blind", label: "Blind" },
    { value: "Deaf", label: "Deaf" },
    { value: "Physically Challenged", label: "Physically Challenged" },
    { value: "Speech Impaired", label: "Speech Impaired" },
    { value: "Albino", label: "Albino" },
  ];

  const handleSpringboardCategoryChange = (selectedOptions) => {
    setEditUserData((prevUserData) => ({
      ...prevUserData,
      springboardCategory: selectedOptions.map((option) => option.value),
    }));
  };

  const handledDisabilityStatusChange = (selectedOptions) => {
    setEditUserData((prevUserData) => ({
      ...prevUserData,
      disabilityStatus: selectedOptions.map((option) => option.value),
    }));
  };

  const handleFormChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditUserData((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };

  const handleFormSelectChanged: React.ChangeEventHandler<HTMLSelectElement> = (
    e
  ) => {
    const { name, value } = e.target;
    setEditUserData((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };

  const [editUser] = useUpdateUserByIDMutation();
  const [updateUserLocation] = useUpdateUserLocationMutation();
  const [createUserForm] = useCreateFormAnswerMutation<FormAnswerRequest>();

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const missingFields = [];
    if (editUserData.springboardCategory.length === 0)
      missingFields.push("Springboard Category");
    if (editUserData.disabilityStatus.length === 0)
      missingFields.push("Disability Status");

    if (missingFields.length > 0) {
      const errorMessage = `${missingFields.join(", ")} must not be empty`;
      toast.error(errorMessage);
      return;
    }

    try {
      const response = await editUser(editUserData);
      const { code, message } = response["data"];
      if (code === 200) {
        toast.success(message);

        const finalResponse = {
          springboardCategory: editUserData.springboardCategory,
        };

        if (location) {
          // Map form state to location data using formQuestionId
          const locationPayload = location.map((loc) => {
            switch (loc.formQuestionId) {
              case 157: // region
                return { id: loc.id, answerProvided: formState.region };
              case 160: // district
                return { id: loc.id, answerProvided: formState.district };
              case 159: // communities
                return { id: loc.id, answerProvided: formState.communities };
              case 158: // town
                return { id: loc.id, answerProvided: formState.town };
              default:
                return loc;
            }
          });

          // Send update request
          await updateUserLocation({ answers: locationPayload });
        }

        if (isNaN(codeLocation) || codeLocation === 404) {
          const payload = {
            formId: 13,
            answeredBy: userId,
            answers: [
              { formQuestionId: 157, answerProvided: formState.region },
              { formQuestionId: 158, answerProvided: formState.town },
              { formQuestionId: 160, answerProvided: formState.district },
              { formQuestionId: 159, answerProvided: formState.communities },
            ],
          };

          await createUserForm(payload);
        }

        navigate(`/auth/signup-registration-2`, {
          state: { finalResponse, userId },
        });
      } else {
        toast.error(message || "Something went wrong");
      }
    } catch (error) {
      toast.error(error.message);
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    setEditUserData((prev) => ({
      ...prev,
      id: userData?.id,
      firstName: userData?.firstName,
      middleName: userData?.middleName,
      lastName: userData?.lastName,
      email: userData?.email,
      phone: userData?.phone,
      whatsappNumber: userData?.whatsappNumber,
      gender: userData?.gender,
      age: userData?.age,
      highestLevelOfEducation: userData?.highestLevelOfEducation,
      springboardCategory: Array.isArray(userData?.springboardCategory)
        ? userData.springboardCategory
        : [],
      disabilityStatus: Array.isArray(userData?.disabilityStatus)
        ? userData.disabilityStatus
        : [],
    }));
  }, [userData]);

  const { data: response, refetch } = useGetUserLocationQuery(userData?.id, {
    skip: !show,
  });
  const location = response?.data?.answers;
  const locationCode = response?.code;
  console.log("locationCode", locationCode);
  const codeLocation = parseInt(locationCode);
  console.log("codeLocation", codeLocation);

  useEffect(() => {
    if (show) {
      refetch();
    }
  }, [show, refetch]);

  useEffect(() => {
    if (location) {
      const region =
        location.find((answer) => answer.formQuestionId === 157)
          ?.answerProvided || "";
      const district =
        location.find((answer) => answer.formQuestionId === 160)
          ?.answerProvided || "";
      const communities =
        location.find((answer) => answer.formQuestionId === 159)
          ?.answerProvided || "";
      const town =
        location.find((answer) => answer.formQuestionId === 158)
          ?.answerProvided || "";

      setFormState({
        region,
        district,
        communities,
        town,
      });
    }
  }, [location]);

  const [formState, setFormState] = useState({
    region: "",
    district: "",
    communities: "",
    town: "",
  });

  const [regions, setRegions] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [communities, setCommunities] = useState([]);
  const [loading, setLoading] = useState(false);

  const [filterFormDropdown] = useFilterFormDropdownsMutation();

  const fetchDropdownSuggestions = useCallback(
    async (questionId, setSuggestions, bindValue = null) => {
      setLoading(true);
      try {
        const response = await filterFormDropdown({
          bind_value: bindValue,
          question_id: questionId,
        }).unwrap();
        setSuggestions(response.data || []);
      } catch (error) {
        console.error(
          `Failed to fetch suggestions for question ${questionId}:`,
          error
        );
      } finally {
        setLoading(false);
      }
    },
    [filterFormDropdown]
  );

  useEffect(() => {
    fetchDropdownSuggestions(157, setRegions);
  }, [fetchDropdownSuggestions]);

  useEffect(() => {
    if (formState.region) {
      fetchDropdownSuggestions(160, setDistricts, formState.region);
    }
  }, [formState.region, fetchDropdownSuggestions]);

  useEffect(() => {
    if (formState.district) {
      fetchDropdownSuggestions(159, setCommunities, formState.district);
    }
  }, [formState.district, fetchDropdownSuggestions]);

  const handleLocationChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <>
      <Modal show={show} onHide={onClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Edit User Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleFormSubmit}>
            <Card>
              <Card.Body>
                <Row>
                  <Col md={6}>
                    <FloatingLabel controlId="floatingInput" label="First Name">
                      <Form.Control
                        type="text"
                        name="firstName"
                        value={editUserData.firstName}
                        onChange={handleFormChanged}
                      />
                    </FloatingLabel>
                  </Col>
                  <Col md={6}>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Middle Name"
                    >
                      <Form.Control
                        type="text"
                        name="middleName"
                        value={editUserData.middleName}
                        onChange={handleFormChanged}
                      />
                    </FloatingLabel>
                  </Col>
                  <Col md={6}>
                    <FloatingLabel controlId="floatingInput" label="Last Name">
                      <Form.Control
                        type="text"
                        name="lastName"
                        value={editUserData.lastName}
                        onChange={handleFormChanged}
                      />
                    </FloatingLabel>
                  </Col>
                  <Col md={6}>
                    <FloatingLabel controlId="floatingInput" label="Email">
                      <Form.Control
                        type="email"
                        name="email"
                        value={editUserData.email}
                        onChange={handleFormChanged}
                      />
                    </FloatingLabel>
                  </Col>
                  <Col md={6}>
                    <FloatingLabel controlId="floatingInput" label="Phone">
                      <Form.Control
                        type="tel"
                        name="phone"
                        value={editUserData.phone}
                        onChange={handleFormChanged}
                      />
                    </FloatingLabel>
                  </Col>
                  <Col md={6}>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="WhatsApp Number"
                    >
                      <Form.Control
                        type="tel"
                        name="whatsappNumber"
                        value={editUserData.whatsappNumber}
                        onChange={handleFormChanged}
                      />
                    </FloatingLabel>
                  </Col>
                  <Col md={6}>
                    <FloatingLabel controlId="floatingSelect" label="Gender">
                      <Form.Select
                        name="gender"
                        value={editUserData.gender}
                        onChange={handleFormSelectChanged}
                      >
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </Form.Select>
                    </FloatingLabel>
                  </Col>
                  <Col md={6}>
                    <FloatingLabel controlId="floatingInput" label="Age">
                      <Form.Control
                        type="number"
                        name="age"
                        value={editUserData.age}
                        onChange={handleFormChanged}
                      />
                    </FloatingLabel>
                  </Col>
                  <Col md={6}>
                    <FloatingLabel
                      controlId="floatingSelect"
                      label="Highest Level of Education"
                    >
                      <Form.Select
                        name="highestLevelOfEducation"
                        value={editUserData.highestLevelOfEducation}
                        onChange={handleFormSelectChanged}
                      >
                        <option value="" disabled>
                          Highest Level of Eduction
                        </option>
                        <option value="No Formal Education">
                          No Formal Education
                        </option>
                        <option value="Primary">Primary</option>
                        <option value="Junior High School (JHS)">
                          Junior High School (JHS)
                        </option>
                        <option value="Senior High School (SHS)">
                          Senior High School (SHS)
                        </option>
                        <option value="TVET (Secondary)">
                          TVET (Secondary)
                        </option>
                        <option value="TVET (Tertiary)">TVET (Tertiary)</option>
                        <option value="HND/Other Diplomas">
                          HND/Other Diplomas
                        </option>
                        <option value="First Degree / Bachelors">
                          First Degree / Bachelors
                        </option>
                        <option value="Master’s Degree">Master’s Degree</option>
                        <option value="Doctorate Degree">
                          Doctorate Degree
                        </option>
                      </Form.Select>
                    </FloatingLabel>
                  </Col>
                  <Col md={6}>
                    <Select
                      isMulti
                      name="springboardCategory"
                      options={springboardCategoryOptions}
                      value={springboardCategoryOptions.filter((option) =>
                        editUserData.springboardCategory.includes(option.value)
                      )}
                      onChange={handleSpringboardCategoryChange}
                      className="basic-multi-select z-3"
                      classNamePrefix="select"
                    />
                  </Col>
                  <Col md={6}>
                    <Select
                      isMulti
                      name="disabilityStatus"
                      options={disabiltyOptions}
                      value={disabiltyOptions.filter((option) =>
                        editUserData.disabilityStatus.includes(option.value)
                      )}
                      onChange={handledDisabilityStatusChange}
                      className="basic-multi-select z-3"
                      classNamePrefix="select"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FloatingLabel controlId="floatingSelect" label="Region">
                      <Form.Select
                        name="region"
                        value={formState.region}
                        onChange={handleLocationChange}
                      >
                        <option value="">Select Region</option>
                        {regions.map((region) => (
                          <option key={region.id} value={region.value}>
                            {region.value}
                          </option>
                        ))}
                      </Form.Select>
                    </FloatingLabel>
                  </Col>
                  <Col md={6}>
                    <FloatingLabel controlId="floatingSelect" label="District">
                      <Form.Select
                        name="district"
                        value={formState.district}
                        onChange={handleLocationChange}
                      >
                        <option value="">Select District</option>
                        {districts.map((district) => (
                          <option key={district.id} value={district.value}>
                            {district.value}
                          </option>
                        ))}
                      </Form.Select>
                    </FloatingLabel>
                  </Col>
                  <Col md={6}>
                    <FloatingLabel
                      controlId="floatingSelect"
                      label="Communities"
                    >
                      <Form.Select
                        name="communities"
                        value={formState.communities}
                        onChange={handleLocationChange}
                      >
                        <option value="">Select Community</option>
                        {communities.map((community) => (
                          <option key={community.id} value={community.value}>
                            {community.value}
                          </option>
                        ))}
                      </Form.Select>
                    </FloatingLabel>
                  </Col>
                  <Col md={6}>
                    <FloatingLabel controlId="floatingInput" label="Town">
                      <Form.Control
                        type="text"
                        name="town"
                        value={formState.town}
                        onChange={handleLocationChange}
                      />
                    </FloatingLabel>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <div className="d-flex justify-content-end mt-3">
              <Button
                variant="secondary"
                onClick={onClose}
                className="me-2 rounded-5"
              >
                Close
              </Button>
              <Button className="rounded-5" variant="primary" type="submit">
                Save Changes
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditUserModal;
