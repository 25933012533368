import { useEffect, useState } from "react";
import { Button, Col, ListGroup, Modal, Row } from "react-bootstrap";
import Background from "../../../../../assets/images/bg_home.webp";
import { useGetUserLocationQuery } from "../../../../../../services/form-answers-service";

const UserModal = ({ userId, userData, show, onClose }) => {
  const { data: response, refetch } = useGetUserLocationQuery(userId, {
    skip: !show,
  });
  const locations = response?.data?.answers;

  useEffect(() => {
    if (show) {
      refetch();
    }
  }, [show, refetch]);

  return (
    <>
      <Modal show={show} onHide={onClose}>
        {/* <Modal.Header closeButton>
          <Modal.Title className="fs-6">Profile details</Modal.Title>
        </Modal.Header> */}
        <Modal.Body className="p-0 m-0">
          <Row className="d-flex m-0 p-0 flex-column align-items-center text-center">
            <Col className="p-0 m-0">
              <div>
                <div
                  style={{
                    background: `url(${Background})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    height: "120px",
                    borderTopLeftRadius: "25px",
                    borderTopRightRadius: "25px",
                  }}
                >
                  <img
                    src={`https://ui-avatars.com/api/?name=${userData?.firstName}+${userData?.lastName}&background=random&size=32`}
                    className="rounded-circle w-25 mt-5"
                    alt="avatar"
                  />
                </div>
              </div>
            </Col>
            <Col className="mt-3  ">
              <div className="mt-5">
                <h4 className="fs-5">
                  {userData?.firstName} {userData?.middleName}{" "}
                  {userData?.lastName}
                </h4>
                <Row>
                  <p className="text-muted font-size-sm">
                    Id: {userData?.id} |{" "}
                    {userData?.gender === "M"
                      ? "Male"
                      : userData?.gender === "F"
                      ? "Female"
                      : "Unknown"}{" "}
                    | {userData?.age} | {userData?.email}
                  </p>
                </Row>
              </div>
              <ListGroup>
                <ListGroup.Item className="d-flex justify-content-between align-items-center users-text flex-wrap">
                  <h6 className="mb-0 users-text">Phone</h6>
                  <span className="text-secondary">{userData?.phone}</span>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex justify-content-between align-items-center users-text flex-wrap">
                  <h6 className="mb-0 users-text">Whatsapp number</h6>
                  <span className="text-secondary">
                    {userData?.whatsappNumber}
                  </span>
                </ListGroup.Item>

                <ListGroup.Item className="d-flex justify-content-between align-items-center users-text flex-wrap">
                  <h6 className="mb-0 users-text">
                    Highest level of education
                  </h6>
                  <span className="text-secondary">
                    {userData?.highestLevelOfEducation}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex justify-content-between align-items-center users-text flex-wrap">
                  <h6 className="mb-0 users-text">Springboard Category</h6>
                  <span className="text-secondary">
                    {userData?.springboardCategory}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex justify-content-between align-items-center users-text flex-wrap">
                  <h6 className="mb-0 users-text">Disability status</h6>
                  <span className="text-secondary text-muted">
                    {userData?.disabilityStatus}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex justify-content-between align-items-center users-text flex-wrap">
                  <h6 className="mb-0 users-text">Registration Channel</h6>
                  <span className="text-secondary">
                    {userData?.registrationChannel}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex justify-content-between align-items-center users-text flex-wrap">
                  <h6 className="mb-0 users-text">Registration Status</h6>
                  <span className="text-secondary">
                    {userData?.registrationStatus}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex justify-content-between align-items-center users-text flex-wrap">
                  <h6 className="mb-0 users-text">Location</h6>

                  <div>
                    {locations?.map((item, index) => (
                      <span
                        key={index}
                        className="text-secondary d-flex justify-content-end"
                      >
                        {item.answerProvided}
                      </span>
                    ))}
                  </div>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex justify-content-between align-items-center users-text flex-wrap">
                  <h6 className="mb-0 users-text">Date Created</h6>
                  <span className="text-secondary">
                    {new Date(userData?.createdOn).toLocaleDateString()}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex justify-content-between align-items-center users-text flex-wrap">
                  <h6 className="mb-0 users-text">Date Updated</h6>
                  <span className="text-secondary">
                    {new Date(userData?.updateOn).toLocaleDateString()}
                  </span>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="dark"
            className="users-text rounded-5"
            onClick={onClose}
          >
            Close
          </Button>
          {/* <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UserModal;
